/*global iNoBounce Modernizr moment */


// #############################################################################
// AJAX SETUP

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

$.ajaxSetup({
  cache: false,
  processData: false,
  beforeSend: function(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader(
        'X-CSRFToken', $('[name=csrfmiddlewaretoken]').val()
      );
    }
  },
  error: function(e) {
    console.log('ERROR:', e, e.status, e.statusText);
  }
});


// #############################################################################
// GLOBAL VARS

var $window = $(window);
var $html = $('html');
var $body = $('body');
var $header = $('#header');
var $dialog_wrapper = $('[data-dialog-wrapper]');
var $infinity_scroll = $('[data-infinity-scroll]');


// #############################################################################
// IOS

Modernizr.addTest('ios', function() {
  return navigator.userAgent.match(/(iPad|iPhone)/g);
});

iNoBounce.disable();


// #############################################################################
// FOCUS

(function() {
  var $links = $('a');

  var focus_method = false;
  var last_focus_method = false;

  $links.on('focus', function() {
    if (!focus_method) {
        focus_method = last_focus_method;
    }

    $links.attr('data-focus-method', focus_method);

    last_focus_method = focus_method;
    focus_method = false;
  });

  $links.on('blur', function() {
    $links.removeAttr('data-focus-method');
  });

  $window.on('blur', function() {
    focus_method = false;
  });

  // Keyboard

  $links.on('keydown', function() {
    focus_method = 'key';
  });

  // Mouse

  $links.on('mousedown', function() {
    if (focus_method === 'touch') {
        return;
    }

    focus_method = 'mouse';
  });

  // Touch

  $links.on('touchstart', function() {
    focus_method = 'touch';
  });
})();


// #############################################################################
// NAVIGATION

(function() {
  var $main_navigation = $('[data-main-navigation]');

  if (!$main_navigation.length) {
    return;
  }

  // Variables

  var $more = $('[data-more]', $main_navigation);
  var $more_link = $('[data-more-link]', $more);
  var $more_ul = $('ul', $more);
  var $login = $('[data-login]', $main_navigation);
  var $nav_items = $('[data-nav-item]', $main_navigation);
  var $nav_links = $('[data-nav-link]', $main_navigation);
  var $logo = $('#logo');

  // Update "more" menu items on resize

  function calcWidth() {
    var nav_width = 0;
    var more_width = $more.outerWidth(true)
    var login_width = $login.outerWidth(true);
    var logo_width = $logo.outerWidth(true);
    var availablespace = $main_navigation.width() - more_width - login_width - logo_width;

    $nav_items.each(function() {
        nav_width += $(this).outerWidth(true);
    });

    if (nav_width > availablespace) {
      var $last_nav_item = $('[data-main-navigation] > ul > [data-nav-item]:not([data-more])').last();

      $last_nav_item.attr('data-nav-item', $last_nav_item.outerWidth(true));
      $last_nav_item.prependTo($more_ul);

      calcWidth();
    }
    else {
      var $first_more_item = $('li', $more).first();

      if (nav_width + $first_more_item.data('nav-item') < availablespace) {
        $first_more_item.insertBefore($more);
      }

      $main_navigation.removeClass('hidden');
    }

    if ($('li', $more).length > 0) {
      $more.removeClass('hidden');
    }
    else {
      $more.addClass('hidden');
    }

    setActive();
  }

  $window.on('resize load',function() {
    calcWidth();
  });

  function setActive() {
    var $active_link = $('.active', $more_ul);

    if ($active_link.length) {
      $more_link.addClass('active');
    }
    else {
      $more_link.removeClass('active');
    }
  }

  // Show/hide submenu in "more"

  var hover_timeout;

  function showSubmenu() {
    $more.addClass('hover');
    $more_link.attr('aria-expanded', 'true');
  }

  function hideSubmenu() {
    $more.removeClass('hover');
    $more_link.attr('aria-expanded', 'false');
  }

  $login.on('mouseover', function() {
    hideSubmenu();
  });

  $more.on('mouseenter', function() {
    showSubmenu();
    clearTimeout(hover_timeout);
  }).on('mouseleave', function() {
    hover_timeout = setTimeout(function() {
      hideSubmenu();
    }, 500);
  });

  $more_link.on('focus', function() {
    showSubmenu()
  }).on('click', function(event) {
    event.preventDefault();
  }).on('touchstart', function() {
    if ($more_link.attr('aria-expanded') === 'true') {
      hideSubmenu();
    }
    else {
      showSubmenu();
    }
  });

  $nav_items.on('mouseover', function() {
    var $this = $nav_items.eq($nav_items.index(this));

    if (!$this.parents('[data-more]').length) {
      hideSubmenu();
    }
  });

  $nav_links.on('focus', function() {
    var $this = $nav_links.eq($nav_links.index(this));

    if (!$this.parents('[data-more]').length) {
      hideSubmenu();
    }
  })
})();


// #############################################################################
// FORM VALIDATION

(function() {

  // Insert error message

  function insertErrorMessage($input, errors) {
    if (!errors) {
      return;
    }

    var input_name = $input.attr('name');
    var input_data_name = $input.attr('data-name');
    var $warning = $('#id_' + input_name + '_warning');
    var $error = $('#id_' + input_name + '_error');

    if ($input.is(':radio')) {
      $error = $('#id_' + input_name + '_0_error');
    }

    if ($.isArray(errors)) {

      // Formset error message

      var id = input_name.match(/\d+/)[0];

      input_name = input_name.split('-').pop();
      errors = errors[id];
    }

    if (errors[input_data_name]) {
      $input.addClass('invalid');
      $warning.addClass('show');

      $error.text(errors[input_data_name].join(' ')).addClass('show');
    }
  }

  // Validate input

  function checkInputValidity($input) {
    if ($input[0].checkValidity()) {
      var input_name = $input.attr('name');
      var $warning = $('#id_' + input_name + '_warning');
      var $error = $('#id_' + input_name + '_error');

      if ($input.is(':radio')) {
        $input = $('[name="' + input_name + '"]');
        $error = $('#id_' + input_name + '_0_error');
      }

      $input.removeClass('invalid');
      $warning.removeClass('show');

      $error.empty().removeClass('show');

      return true;
    }

    return false;
  }

  // Validate before submit

  $body.on('blur input', '[data-form] :input:visible:not(button)', function() {
    var $input = $(this);
    var is_valid = checkInputValidity($input);

    if (!is_valid) {
      var $form = $input.parents('form');

      $.ajax({
        contentType: false,
        data: new FormData($form[0]),
        enctype: 'multipart/form-data',
        type: 'POST',
        url: $form.attr('action'),
        success: function(data) {
          insertErrorMessage($input, data['errors']);
        },
      });
    }
  });

  // Validate after submit

  $body.on('submit', '[data-form]', function(event) {
    event.preventDefault();

    var $form = $(this);
    var $inputs = $(':input:visible:not(button)', $form);
    var $button = $('button', $form);
    var $spinner = $('.loading-spinner', $form);

    if (!$form[0].checkValidity()) {
      $form.addClass('was-validated');
      $form.find('[required]:invalid').first().focus();

      $inputs.each(function(index) {
        checkInputValidity($inputs.eq(index));
      });
    }

    var $form_data = new FormData($form[0]);

    // Employee ID for employee filter

    var $calendar_select = $('[data-calendar-select]');
    var employee_id = $calendar_select.attr('data-employee-id') || '';

    $form_data.append('employee_id', employee_id);

    // AJAX

    $.ajax({
      contentType: false,
      data: $form_data,
      enctype: 'multipart/form-data',
      type: 'POST',
      url: $form.attr('action'),
      success: function(data) {
        if (data['submit'] === 'successful') {
          if (data['dialog']) {
            $('[data-dialog-close]').click();
          }

          if (data['updates']) {
            data['updates'].forEach(function(item) {
              $(item['wrapper']).html(item['content']);
            });
          }

          if (data['update_calendar']) {
            updateCalendar();
          }
        }
        else {
          $inputs.each(function(index) {
            insertErrorMessage($inputs.eq(index), data['errors']);
          });
        }

        // hide spinner / enable button

        $button.removeAttr('disabled');
        $spinner.addClass('hidden');
      },
    });

    // show spinner / disable button

    $button.attr('disabled', 'disabled');
    $spinner.removeClass('hidden');
  });
})();


// #############################################################################
// FORMSET

function initFormset() {
  var $formsets = $('[data-formset]');

  $formsets.each(function(index) {
    var $formset = $formsets.eq(index);
    var prefix = $formset.data('formset');
    var prepend = $formset.data('formset-prepend');

    var $body = $('[data-formset-body]', $formset)
    var $template = $('[data-formset-empty-item]', $formset).html();

    var $total_forms = $('#id_' + prefix + '-TOTAL_FORMS');
    var min_num_forms = $('#id_' + prefix + '-MIN_NUM_FORMS').val();
    var max_num_forms = $('#id_' + prefix + '-MAX_NUM_FORMS').val();

    // Add formset

    $('[data-formset-add]', $formset).on('click', function(event) {
      event.preventDefault();

      var $items = $('[data-formset-item]', $body);
      var $visible_items = $('[data-formset-item]:visible', $body);
      var count = $items.length;

      if ($visible_items.length + 1 > max_num_forms) {
        return;
      }

      var $new_item = $($template.replace(/__prefix__/g, count));

      if (prepend) {
        $body.prepend($new_item);

      }
      else {
        $body.append($new_item);
      }

      $new_item.find(':input:visible').first().focus();
      $new_item.attr('data-formset-item', $visible_items.length);

      $total_forms.val(count + 1);
    });

    // Delete formset

    $body.on('click', '[data-formset-delete]', function(event) {
      event.preventDefault();

      var count = $('[data-formset-item]', $body).length;

      if (count == min_num_forms) {
        return;
      }

      var $item = $(this).parents('[data-formset-item]');

      $(':input:visible', $item).val('');

      $item.addClass('hidden');

      $('[name$="-DELETE"]', $item).click();
    });

    // Submit formset

    function submitFormSet($form) {
      $.ajax({
        contentType: false,
        data: new FormData($form[0]),
        type: 'POST',
        url: $form.attr('action'),
        // success: function(data) {
        //   var $updated_items = data['items'];
        // },
      });
    }

    $formset.submit(function(event) {
      event.preventDefault();

      submitFormSet($formset);
    });
  });
}


function initFormsetCheckDuplicateSelect() {
  var $formsets = $('[data-formset]');

  $formsets.each(function(index) {
    var $formset = $formsets.eq(index);

    var $select = $('[data-duplicate-check]', $formset);

    if ($select.length === 0) {
      return;
    }

    var $body = $('[data-formset-body]', $formset);
    var $add_button = $('[data-formset-add]', $formset);
    var $delete_button = $('[data-formset-delete]', $formset);

    // Disable selected options

    function disableSelectedOptions() {
      var $selects = $('select:visible', $body);

      var ids = $selects.map(function() {
        var value = $('option:selected', this).val();

        if (value) {
          return value;
        }
      }).get();

      $('option', $selects).removeAttr('disabled');

      $selects.each(function() {
        var _this = this;

        $.each(ids, function(index, value) {
          var $option_selected = $('option:selected', _this);
          var $option = $('option[value="' + value + '"]', _this);

          if ($option_selected.val() != $option.val()) {
            $option.attr('disabled', 'disabled');
          }
        });
      });
    }

    disableSelectedOptions();

    // Add formset

    $add_button.on('click', function() {
      disableSelectedOptions();
    });

    // Change formset

    $select.on('change', function() {
      disableSelectedOptions();
    });

    // Delete formset

    $delete_button.on('click', function() {
      disableSelectedOptions();
    });
  });
}


// #############################################################################
// MONTH CALENDAR: FILTER AND LOAD

function loadCalendar(year, month, today) {
    var $calendar_select = $('[data-calendar-select]');
    var $option = $calendar_select.find(':selected');
    var current_day = $('[data-current-day]').val();
    var month_url = $option.data('month-url');
    var details_url = $calendar_select.val();
    var employee_id = $calendar_select.attr('data-employee-id');

    if (employee_id) {
      var employee_month_url = $option.data('employee-month-url');

      if (employee_month_url) {
        details_url = employee_month_url + employee_id + '/';
      }
      else {
        $calendar_select.removeAttr('data-employee-id');
      }
    }

    var date = year + '/' + month;

    if (current_day.indexOf(date) === 0) {
      date = current_day
    }

    $.ajax({
      url: month_url + date,
      success: function(data) {
        $('[data-month-wrapper]').html(data);
      }
    });

    window.loadMonthDetailsCalendar(
      details_url + year + '/' + month + '/', 'next', true, today
    );
  }

function updateCalendar() {
    var year = $('[data-filter-year]').val();
    var month = $('[data-filter-month]').val();

    loadCalendar(year, month, false);
}

function initCalendarFilter() {
  $body.on('change', '[data-filter-month]', function() {
    var $this = $(this);
    var year = $('[data-filter-year]').val();
    var month = $this.val();

    loadCalendar(year, month, false);
  });

  $body.on('change', '[data-filter-year]', function() {
    var $this = $(this);
    var year = $this.val();
    var month = $('[data-filter-month]').val();

    loadCalendar(year, month, false);
  });

  $body.on('click', '[data-today]', function() {
    var current_day = $('[data-current-day]').val();
    var $today_date = current_day.split('/');
    var year = $today_date[0];
    var month = $today_date[1];

    $('[data-filter-year]').val(year);
    $('[data-filter-month]').val(month);

    loadCalendar(year, month, true);
  });

  $body.on('change', '[data-calendar-select]', function() {
    var current_day = $('[data-current-day]').val();
    var $today_date = current_day.split('/');
    var year = $today_date[0];
    var month = $today_date[1];

    $(this).removeAttr('data-employee-id');

    loadCalendar(year, month, true, false);
  });

  $body.on('click', '[data-reset-month-details]', function() {
    var $this = $(this);
    var $date = $this.attr('href').split('-');
    var $calendar_select = $('[data-calendar-select]');

    $('[data-employee-month-details]').removeClass('active');

    $calendar_select.removeAttr('data-employee-id');

    loadCalendar($date[0].slice(1), $date[1], true);
  });

  $body.on('click', '[data-employee-month-details]', function() {
    var $this = $(this);
    var employee_id = $this.data('employee-month-details');
    var $date = $this.attr('href').split('-');
    var $calendar_select = $('[data-calendar-select]');

    $('[data-employee-month-details]').removeClass('active');
    $('[data-employee-month-details="' + employee_id + '"]').addClass('active');

    $calendar_select.attr('data-employee-id', employee_id);

    loadCalendar($date[0].slice(1), $date[1], true);
  });
}

initCalendarFilter();


// #############################################################################
// LOAD CALENDAR DETAILS

function loadCalendarDetails() {
  if (!$body.hasClass('calendar')) {
    return;
  }

  var $calendar_overview = $('#calendar_overview');
  var $calendar_details_header = $('#calendar_details_header');
  var $calendar_details = $('#calendar_details');

  $body.on('click', '[data-calendar-item]', function(event) {
    event.preventDefault();

    $calendar_overview.removeClass('show');
    $calendar_details_header.addClass('show');
    $calendar_details.addClass('show');
  });

  $body.on('click', '#calendar_back', function(event) {
    event.preventDefault();

    $calendar_overview.addClass('show');
    $calendar_details_header.removeClass('show');
    $calendar_details.removeClass('show');
  });
}

loadCalendarDetails();


// #############################################################################
// MONTH CALENDAR: SCROLL TO DATE

(function() {
  $body.on('click', '[data-calendar-item]', function(event) {
    event.preventDefault();

    var href = $(this).attr('href');

    var $anchor = $(href);

    $infinity_scroll.animate({
      scrollTop:
        $infinity_scroll.scrollTop()
        + $anchor.offset().top + 1
        - $('[data-month-details-item]').outerHeight()
        - $header.height()
        - 100
    }, 250);
  });
})();


// #############################################################################
// MONTH DETAILS CALENDAR: INIT

(function() {
  var $wrapper = $('[data-month-details-wrapper]');

  if (!$wrapper.length) {
    return;
  }

  var height = $infinity_scroll.height();
  var $calendar_select = $('[data-calendar-select]');
  var month_details_url = $calendar_select.val();

  window.calendar_init = false;
  window.calendar_is_initalized = false;

  function scrollToFirstDateOfTheMonth(index) {
    $infinity_scroll.scrollTop(
      $infinity_scroll.find('[data-month-anchor]').eq(index).offset().top
      - $header.height()
      - $('[data-month-details-item]').outerHeight()
    );
  }

  function scrollToTodayDate() {
    var $today_anchor = $infinity_scroll.find('[data-today-anchor]').first();

    if (!$today_anchor.length) {
      scrollToFirstDateOfTheMonth(1);
    }

    if ($today_anchor.length) {
      $infinity_scroll.scrollTop(
        $today_anchor.offset().top
        + ($today_anchor.height() / 2)
        - $header.height()
        - (height / 2)
      );
    }
  }

  function loadMonthDetailsCalendar(url, direction, init, today) {
    if (init) {
      window.calendar_init = true;
      window.calendar_is_initalized = false;

      $wrapper.empty().removeClass('show');
    }

    if (today) {
      window.today = today
    }

    $.ajax({
      url: url,
      success: function(data) {
        var $content = $(data);
        var $month = $('[data-month-details]');

        if ($month.length) {
          var $items = $content.find('[data-month-details-item]');

          if (direction == 'next') {
            $month.attr('data-next-month-url', $content.attr('data-next-month-url'));
            $month.attr('data-year-of-next-month', $content.attr('data-year-of-next-month'));

            $month.append($items);
          }
          else {
            $month.attr('data-prev-month-url', $content.attr('data-prev-month-url'));

            $month.prepend($items);

            if (window.calendar_is_initalized) {
              scrollToFirstDateOfTheMonth(1);
            }
            else {
              if (window.calendar_init) {
                if (window.today) {
                  scrollToTodayDate();

                  window.today = false;
                }
                else {
                  scrollToFirstDateOfTheMonth(1);
                }

                window.calendar_init = false;
              }
              else {
                scrollToTodayDate();
              }

              $wrapper.addClass('show');

              window.calendar_is_initalized = true;
            }
          }
        }
        else {
          $wrapper.html($content);
        }

        $month = $('[data-month-details]');

        var month_height = $month.height();

        if (month_height === 0) {
          month_height = 2000;
        }

        if (month_height <= height) {
          loadMonthDetailsCalendar($month.attr('data-next-month-url'), 'next');
        }
        else {
          if (!window.calendar_is_initalized) {
            loadMonthDetailsCalendar($month.attr('data-prev-month-url'), 'prev');
          }
        }
      },
    });
  }

  loadMonthDetailsCalendar(month_details_url, 'next');

  window.loadMonthDetailsCalendar = loadMonthDetailsCalendar
})();


// #############################################################################
// MONTH DETAILS CALENDAR: INFINITY SCROLL

(function() {
  var max_year = $('[data-filter-year] option').last().val();

  $infinity_scroll.on('scroll', function() {
    if (!window.calendar_is_initalized) {
      return;
    }

    var $month = $infinity_scroll.find('[data-month-details]');
    var scroll_top = $infinity_scroll.scrollTop();
    var height = $infinity_scroll.height();
    var total_height = $infinity_scroll.prop('scrollHeight');

    if (scroll_top + height >= total_height) {
      var next_month_url = $month.attr('data-next-month-url');
      var year_of_next_month = $month.attr('data-year-of-next-month');

      if (year_of_next_month > max_year) {
        return;
      }
      window.loadMonthDetailsCalendar(next_month_url, 'next');
    }
    else if (scroll_top == 0) {
      var prev_month_url = $month.attr('data-prev-month-url');

      window.loadMonthDetailsCalendar(prev_month_url, 'prev');
    }
  });
})();


// #############################################################################
// LOAD EMPLOYEE DETAILS

function loadEmployeeDetails() {
  if (!$body.hasClass('employee')) {
    return;
  }

  var $employee_details = $('#employee_details');
  var $employees_header = $('#employees_header');
  var $employees_lists = $('#employees_lists');
  var $employees_preloader = $('#employees_preloader');

  initFormset();
  initFormsetCheckDuplicateSelect();

  $body.on('click', '[data-show-employee]', function(event) {
    event.preventDefault();

    window.scrollTo(0, 0);

    $employees_header.removeClass('show');
    $employees_lists.removeClass('show');
    $employees_preloader.addClass('show');

    $.ajax({
      url: $(this).attr('href'),
      success: function(data) {
        $employee_details.html(data).addClass('show');
        $employees_preloader.removeClass('show');

        initFormset();
        initFormsetCheckDuplicateSelect();
      },
    });
  });

  $body.on('click', '#employee_back', function(event) {
    event.preventDefault();

    $employee_details.removeClass('show');
    $employees_header.addClass('show');
    $employees_lists.addClass('show');
  });
}

loadEmployeeDetails();


// #############################################################################
// FIX EMPLOYEE DETAILS

function initEmployeesList() {
  var $employees_lists = $('#employees_lists');

  if ($employees_lists.length === 0) {
    return;
  }

  var height = $('#employees_header').outerHeight()
             + $employees_lists.outerHeight();

  if (height <= $window.height()) {
    $employees_lists.addClass('sticky');
  }
  else {
    $employees_lists.removeClass('sticky');
  }
}

$window.on('resize', function() {
  initEmployeesList();
});

initEmployeesList();


// #############################################################################
// INIT EMPLOYEE AVAILABILITIY

function initEmployeeAvailability() {
  function loadEmployeeAvailability(url) {
    $('[data-employee-availability]').removeClass('selected');
    $(this).addClass('selected');

    var $calendar_select = $('[data-calendar-select]');
    var employee_id = $calendar_select.attr('data-employee-id');
    var $option = $calendar_select.find(':selected');
    var employee_filter = $option.attr('data-employee-filter');

    url = url + '?employee_filter=' + employee_filter;

    if (employee_id) {
      url = url + '&amp;employee_id=' + employee_id;
    }

    $.ajax({
      url: url,
      success: function(data) {
        $('#employee_availability_wrapper').html(data);
      },
    });
  }

  $body.on('click','button[data-employee-availability], a[data-employee-availability]', function(event) {
    event.preventDefault();

    var availability_url = $(this).data('employee-availability');

    loadEmployeeAvailability(availability_url);
  });

  $body.on('change','select[data-employee-availability]', function() {
    var year = $('[data-filter-year]').val();
    var month = $('[data-filter-month]').val();

    var availability_url = $(this).data('employee-availability');

    availability_url = availability_url + year + '/' + month + '/'

    loadEmployeeAvailability(availability_url);
  });
}

initEmployeeAvailability();


// #############################################################################
// DIALOG

function initDialog() {
  $body.on('click', '[data-dialog]', function(event) {
    event.preventDefault();

    $.ajax({
      url: $(this).attr('href'),
      success: function(data) {
        $dialog_wrapper.html(data);

        initFormset();
        initFormsetCheckDuplicateSelect();
        showAssignmentStatus();

        setTimeout(function() {
          $dialog_wrapper.addClass('show');
          $html.addClass('no-scroll');

          if (Modernizr.ios) {
            iNoBounce.enable();
          }
        }, 50);
      },
    });
  });

  $body.on('click', '[data-dialog-close]', function(event) {
    event.preventDefault();

    $dialog_wrapper.removeClass('show');
    $html.removeClass('no-scroll');

    if (Modernizr.ios) {
      iNoBounce.disable();
    }
  });

  $dialog_wrapper.on('transitionend', function(event) {
    if(this !== event.target) {
      return;
    }

    if($dialog_wrapper.hasClass('show')) {
      $('[data-dialog-close="focus"]').focus();
    }
  });

  $(document).on('keyup', function(event) {
    if($dialog_wrapper.hasClass('show')) {
      if(event.key === 'Escape') {
        $('[data-dialog-close]').click();
      }
    }
  });
}

initDialog();


// #############################################################################
// DIALOG: SHOW ASSIGNMENT STATUS

function showAssignmentStatus() {
  var $event_status = $('[data-event-status]');

  if ($event_status.length === 0) {
    return;
  }

  $event_status.each(function(index) {
    var $status = $event_status.eq(index);
    var prefix = $status.data('event-status');
    var $input = $('[id="id_' + prefix + '-status"]');
    var value = $input.val();

    if (value) {
      var $icon = $('[data-event-status-icon="' + value + '"]', $status);
      var $delete = $('[data-formset-delete="' + prefix + '"');

      $icon.removeClass('hidden');

      if (value === '0') { // 0 = agreed
        $delete.remove();
      }
    }
  });
}


// #############################################################################
// DIALOG: ADD SPECIAL DATE

(function() {
  function updateEndDate() {
    var date_start = $('#id_dialog-date_start').val();
    var time_start = $('#id_dialog-time_start').val();

    if (date_start && time_start) {
      var datetime = date_start + 'T' + time_start;
      var end_date = moment(datetime).add(1, 'hour').format('YYYY-MM-DD');
      var end_time = moment(datetime).add(1, 'hour').format('HH:mm');

      $('#id_dialog-date_end').val(end_date);
      $('#id_dialog-time_end').val(end_time);
    }
  }

  $body.on('input', '#id_dialog-time_start', updateEndDate);
  $body.on('input', '#id_dialog-date_start', updateEndDate);
})();


// #############################################################################
// DIALOG: AGREE OR DECLINE ASSIGNMENT

(function() {
  $body.on('click', '[data-attend-status]', function() {
    var $button = $(this);
    var $attend_status = $('[name="attend_status"]');
    var attend_status = $button.attr('data-attend-status');

    if (attend_status === 'agree') {
      $attend_status.val(0) // 0 = agree
    }
    else if (attend_status === 'decline') {
      $attend_status.val(1) // 1 = decline
    }
  });
})();


// #############################################################################
// DIALOG: SAVE

(function() {
  $body.on('click', '[data-dialog-save]', function() {
    var $button = $(this);
    var $form = $('form', $button.parents('[data-dialog-wrapper]'));

    $form.submit();

    return false;
  });
})();


// ###############################################################################
// PHOTO PREVIEW

(function() {
  $body.on('change', '[name="photo"]', function(event) {
    var $reader = new FileReader();
    var $img = $('[for="' + event.target.id + '"] img');
    var file = event.target.files[0];

    $reader.onload = function(e) {
      $img.attr('src', e.target.result)
    };

    $reader.readAsDataURL(file);
  })
})();


// #############################################################################
// TIMESHEET

function initTimesheet() {

  // Update

  $body.on('change', '[data-attend]', function() {
    $(this).parents('form').submit();
  });

  $body.on('input', '[data-guardian-hours]', function() {
    $(this).parents('form').submit();
  });

  // Search

  var $filter = $('[data-filter-tag]');

  $body.on('input', '[name="search"]', function() {
    var search = $(this).val();

    $filter.removeClass('hidden').filter(function() {
      return $(this).data('filter-tag').indexOf(search.toLowerCase()) === -1;
    }).addClass('hidden');
  });
}

initTimesheet();
